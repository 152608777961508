<template>
  <div class="extration-bdd-frais">
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on }">
        <div class="block-icon mr-2" v-on="on" @click="openModal">
          <IconEmail></IconEmail>
        </div>
      </template>
      <span>Envoyer Email</span>
    </v-tooltip>
    <mail-vendeur
      @eventMailSendRefresh="eventMailSendRefresh = true"
      :isOpen="isOpen"
      @custumChange="closeModal"
      :dataToUse="getDataForMailModelCdg"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    dataToUse: { required: true },
    selecteur: { default: 'id' },
    hiddenContent: { required: false }
  },
  components: {
    mailVendeur: () => import('@/views/component/mail/mailVendeur.vue'),
    IconEmail: () => import('@/assets/img/IconEmail.vue')
  },
  data() {
    return {
      error: null,
      isOpen: false,
      eventMailSendRefresh: false
    };
  },
  methods: {
    ...mapActions([
      'sendMailMasterFilial',
      'fetchDataForMailModelCdg',
      'resetDataModalMailCdg'
    ]),
    openModal() {
      this.isOpen = true;
      this.fetchDataForMailModelCdg({
        data: this.dataToUse,
        selecteur: this.selecteur
      });
    },
    closeModal() {
      this.isOpen = false;
      if (this.eventMailSendRefresh == true) {
        this.$emit('eventMailSendRefreshTable');
      }
      this.eventMailSendRefresh = false;

      this.resetDataModalMailCdg();
    }
  },
  computed: {
    ...mapGetters([
      'getDataForMailModelCdg',
      'getErrorMailingMasterFiliale',
      'getSuccessSendMailsMasterFliliale',
      'getOtherMailMaster'
    ])
  },
  mounted() {}
};
</script>

<style scoped lang="scss"></style>
<style lang="scss">
.align-item-extract-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.modal-extaction-bdd-frais {
  .d-block,
  #fieldset-horizontal,
  #fieldset-horizontal-type {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 3%;
    margin-top: 8px;
    margin-bottom: 1px;
  }
  .form-modal-custom-style {
    text-align: center;
  }
}
</style>
